import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="inner">
        <div className="links">
          <a href="https://westack.live/about">关于我们</a>
          <a href="https://westack.live/blog">博客</a>
          <a href="https://westack.live/contact">联系我们</a>
        </div>
        &copy; Copyright {new Date().getFullYear()} westack.live{" "}
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          粤ICP备20013360号-2
        </a>
      </div>
    </div>
  );
}
